import React from 'react'
import styles from './styles.module.scss'
import dataMenu from "../../data/dataMenu";
import { useState, useContext, useEffect } from 'react'
import { CartContext } from '../../Context/CartContext';

export const FavoriteItem = ({item}) => {
  return (
     <div className={styles.itemContainer} key={item.id}>
       <div className={styles.item}>
         <img src={item.img} alt={item.title} className="styles.photo" />
         <div className={styles.itemInfo}>
           <h3>{item.title}</h3>
           <p>${item.price}<small>.00</small></p>
         </div>
       </div>
     </div>
  )
}