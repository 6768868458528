import React, {useContext} from 'react'
import { CartContext } from '../../Context/CartContext.jsx'
import styles from './style.module.scss'
import vector from "../../images/Vector.svg"

export default function OrderItem({item}) {
  const { deleteItemToCart, addItem } = useContext(CartContext)
  const { id, title, img, price, amount, key } = item
  return (
    <div className={styles.itemContainer} key={id}>
      <div className={styles.item}>
        <img src={img} alt={title} className="styles.photo" />
        <div className={styles.itemInfo}>
          <h3>{title}</h3>
          <p>${price}<small>.00</small></p>
          <div className={styles.monto}><h5>{amount}</h5></div>
        </div>
      </div>
      <img src={vector} alt="vector" className={styles.vector} onClick={() => deleteItemToCart(item)} />
    </div>
  )
}