import styles from './styles.module.scss'
import { useContext } from 'react'
import { CartContext } from '../../Context/CartContext.jsx'

const Menu = ({ items }) => {

  const {addItemToCart} = useContext(CartContext)
  return (
    <div className={styles.sectionCenter}>
      {items.map((menuItem) => {
        const { id, title, img, price} = menuItem
        return (
          <article key={id} className={styles.item}>
            <img src={img} alt={title} className={styles.photo} />
            <div className={styles.itemInfo}>
                <h3>{title}</h3>
                <h4 className="styles.price">${price}<small>.00</small></h4>
                <button onClick={() => addItemToCart(menuItem)}>
                  <svg className={styles.svgMore} width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.28571 11.5714V6.28571M6.28571 6.28571V1M6.28571 6.28571H11.5714M6.28571 6.28571H1" stroke="#AEAEAE" strokeWidth="2" strokeLinecap="round"/>
                  </svg>
                </button>
            </div>
          </article>
        )
      })}
    </div>
  )
}

export default Menu