import { React, useState, useContext, useEffect } from 'react'
import styles from './styles.module.scss'
import { CartContext } from '../../Context/CartContext'
import OrderItem from '../OrderItem'
import Advertencia from "../../images/Advertencia.svg"
import Sheet from 'react-modal-sheet';

export const Order = () => {
  const [productsLength, setProductsLength] = useState(0)
  const [orden, setOrden] = useState(false)
  const {cartItems, clear} = useContext(CartContext)
  const [isOpen, setOpen] = useState(false);

  useEffect(() => {
    setProductsLength(
      cartItems.reduce((previous, current) => previous + current.amount, 0)
    )
  }, [cartItems]);

  const total = cartItems.reduce(
    (previous, current) => previous + current.price * current.amount,
    0
  )

    const [nombre, setNombre] = useState('');
    const getData = () => {
      return localStorage.getItem('nombre')
    }
    useEffect(() => {
      setNombre(getData());
    }, [])
  

    const [numero, setNumero] = useState('');
    const getDataNumero = () => {
      return localStorage.getItem('numero')
    }
    useEffect(() => {
      setNumero(getDataNumero());
    }, [])

    const [direccion, setDireccion] = useState('');
    const getDataDireccion = () => {
      return localStorage.getItem('direccion')
    }
    useEffect(() => {
      setDireccion(getDataDireccion());
    }, [])

    const [identidad, setIdentidad] = useState('');
    const getDataIdentidad = () => {
      return localStorage.getItem('identidad')
    }
    useEffect(() => {
      setIdentidad(getDataIdentidad());
    }, [])

    const [especificaciones, setEspecificaciones] = useState('');
    const getDataEspecificaciones = () => {
      return localStorage.getItem('especificaciones')
    }
    useEffect(() => {
      setEspecificaciones(getDataEspecificaciones());
    }, [])

  return (
    <>
    {orden === false ? (
    <div className={styles.containerOrder}>
      <div>
        <h2 className={styles.order}>Mi orden</h2>
      </div>
      <div className={styles.contList}>
        <h4>Listado del pedido</h4>
        <svg width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" alt="vectorBas" onClick={clear}><path d="M22.8 4.80132H16.8V2.79746C16.7718 2.0293 16.4402 1.30363 15.8779 0.779551C15.3155 0.255468 14.5683 -0.0242725 13.8 0.00165401H10.2C9.43171 -0.0242725 8.68447 0.255468 8.12212 0.779551C7.55975 1.30363 7.22815 2.0293 7.2 2.79746V4.80132H1.2C0.88174 4.80132 0.576515 4.92774 0.351472 5.15277C0.126428 5.3778 0 5.683 0 6.00124C0 6.31948 0.126428 6.62468 0.351472 6.84971C0.576515 7.07474 0.88174 7.20116 1.2 7.20116H2.4V20.4002C2.4 21.355 2.77928 22.2706 3.45442 22.9457C4.12955 23.6207 5.04522 24 6 24H18C18.9548 24 19.8705 23.6207 20.5456 22.9457C21.2207 22.2706 21.6 21.355 21.6 20.4002V7.20116H22.8C23.1183 7.20116 23.4235 7.07474 23.6485 6.84971C23.8736 6.62468 24 6.31948 24 6.00124C24 5.683 23.8736 5.3778 23.6485 5.15277C23.4235 4.92774 23.1183 4.80132 22.8 4.80132ZM9.6 16.8005C9.6 17.1187 9.47357 17.4239 9.24853 17.649C9.02348 17.874 8.71826 18.0004 8.4 18.0004C8.08174 18.0004 7.77652 17.874 7.55147 17.649C7.32643 17.4239 7.2 17.1187 7.2 16.8005V12.0008C7.2 11.6826 7.32643 11.3774 7.55147 11.1524C7.77652 10.9273 8.08174 10.8009 8.4 10.8009C8.71826 10.8009 9.02348 10.9273 9.24853 11.1524C9.47357 11.3774 9.6 11.6826 9.6 12.0008V16.8005ZM9.6 2.79746C9.6 2.60547 9.852 2.40149 10.2 2.40149H13.8C14.148 2.40149 14.4 2.60547 14.4 2.79746V4.80132H9.6V2.79746ZM16.8 16.8005C16.8 17.1187 16.6736 17.4239 16.4485 17.649C16.2235 17.874 15.9183 18.0004 15.6 18.0004C15.2817 18.0004 14.9765 17.874 14.7515 17.649C14.5264 17.4239 14.4 17.1187 14.4 16.8005V12.0008C14.4 11.6826 14.5264 11.3774 14.7515 11.1524C14.9765 10.9273 15.2817 10.8009 15.6 10.8009C15.9183 10.8009 16.2235 10.9273 16.4485 11.1524C16.6736 11.3774 16.8 11.6826 16.8 12.0008V16.8005Z" fill="#353535"/></svg>
      </div>
      <div className={cartItems.length >= 4 ? styles.containerItemsScroll : styles.containerItems}>
        {cartItems.map((item, i) => (
          <OrderItem key={i} item={item}/>
        ))}
      </div>
      <div className={styles.precio}>
        <div className={styles.flex}>
          <h4>Envío</h4>
          <p>$100.00</p>
        </div>
        <div className={styles.flex}>
          <h4>Items totales</h4>
          <p>${total}</p>
        </div>
      </div>
      <button className={styles.btn} onClick={() => setOrden(true)}>Ir al checkout</button>
    </div>
    ) : (
      <div className={styles.containerOrder}>
      <div>
        <h2 className={styles.order}>Mi orden</h2>
      </div>
      <div className={styles.contList}>
      <h4>Checkout</h4>
      <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setOrden(false)}><path fill-rule="evenodd" clip-rule="evenodd" d="M0.316867 0.330729C0.519817 0.127635 0.795041 0.0135436 1.08201 0.0135436C1.36899 0.0135436 1.64421 0.127635 1.84716 0.330729L6.49323 4.98148L11.1393 0.330729C11.2391 0.22726 11.3586 0.144729 11.4906 0.0879524C11.6226 0.031176 11.7647 0.00129087 11.9084 4.09035e-05C12.0521 -0.00120906 12.1946 0.0262009 12.3276 0.0806718C12.4606 0.135143 12.5814 0.215584 12.683 0.317301C12.7846 0.419018 12.865 0.539974 12.9194 0.673112C12.9738 0.80625 13.0012 0.948903 13 1.09275C12.9987 1.23659 12.9689 1.37875 12.9121 1.51092C12.8554 1.64309 12.773 1.76263 12.6696 1.86256L8.02353 6.51331L12.6696 11.1641C12.8667 11.3684 12.9758 11.642 12.9734 11.9261C12.9709 12.2101 12.8571 12.4818 12.6564 12.6827C12.4558 12.8836 12.1843 12.9975 11.9006 13C11.6168 13.0024 11.3434 12.8932 11.1393 12.6959L6.49323 8.04515L1.84716 12.6959C1.64305 12.8932 1.36967 13.0024 1.08591 13C0.802147 12.9975 0.530707 12.8836 0.33005 12.6827C0.129393 12.4818 0.0155741 12.2101 0.0131083 11.9261C0.0106425 11.642 0.119727 11.3684 0.316867 11.1641L4.96294 6.51331L0.316867 1.86256C0.113977 1.65941 0 1.38391 0 1.09665C0 0.809384 0.113977 0.533884 0.316867 0.330729Z" fill="#353535"/></svg>

      </div>
      <h5 className={styles.confirm}>Confirme su pedido</h5>
      
      <div className={styles.Advertencia}>
        <img src={Advertencia} alt="Advertencia"/>
        <p>El pago se efectuará al llegar la orden, expecificamente en efectivo y el envío es propio del negocio. Al finalizar el checkout le llegará el recibo vía WhatsApp.</p>
      </div>

      <div className={styles.containerText}>
        <div className={styles.infoClient}>
          <p className={styles.infoClientQuest}>Ingrese su nombre*</p>
          <p className={styles.infoClientResp}>{nombre}</p>
        </div>

        <div className={styles.infoClient}>
          <p className={styles.infoClientQuest}>Ingrese su número de teléfono*</p>
          <p className={styles.infoClientResp}>{numero}</p>
        </div>

        <div className={styles.infoClient}>
          <p className={styles.infoClientQuest}>Ingrese su dirección*</p>
          <p className={styles.infoClientResp}>{direccion}</p>
        </div>

        <div className={styles.infoClient}>
          <p className={styles.infoClientQuest}>Ingrese su documento de identidad</p>
          <p className={styles.infoClientResp}>{identidad}</p>
        </div>

        <div className={styles.infoClient}>
          <p className={styles.infoClientQuest}>Especificaciones</p>
          <p className={styles.infoClientResp}>{especificaciones}</p>
        </div>
      </div>
      <button className={styles.btn2} onClick={() => setOrden(true)}>Generar recibo</button>
    </div>
      )}

      <div className={styles.buttonMobile} onClick={() => setOpen(true)}>
        <div className={styles.containerText}>
          <p>{cartItems.length} items</p>
          <p>${total}</p>
        </div>
        <svg width="28" height="21" viewBox="0 0 28 21" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1.375 17.375H26.125C26.8813 17.375 27.5 17.9937 27.5 18.75C27.5 19.5063 26.8813 20.125 26.125 20.125H1.375C0.61875 20.125 0 19.5063 0 18.75C0 17.9937 0.61875 17.375 1.375 17.375ZM16.28 4.71125C16.4578 4.29252 16.5293 3.83632 16.488 3.38329C16.4468 2.93025 16.2942 2.49444 16.0437 2.11468C15.7933 1.73492 15.4528 1.423 15.0526 1.20671C14.6524 0.990415 14.2049 0.876462 13.75 0.875C13.2951 0.876462 12.8476 0.990415 12.4474 1.20671C12.0472 1.423 11.7067 1.73492 11.4563 2.11468C11.2058 2.49444 11.0532 2.93025 11.012 3.38329C10.9707 3.83632 11.0422 4.29252 11.22 4.71125C5.84375 5.825 1.74625 10.4037 1.375 16H26.125C25.7537 10.4037 21.6562 5.825 16.28 4.71125Z" fill="white"/>
        </svg>
      </div>

      <Sheet isOpen={isOpen} onClose={() => setOpen(false)} snapPoints={[500, 400, 100, 0]}>
        <Sheet.Container>
          <Sheet.Header />
            <Sheet.Content>
              {orden === false ? (
                <div className={styles.containerOrderMobile}>
                  <div>
                    <h2 className={styles.order}>Mi orden</h2>
                  </div>
                  <div className={styles.contList}>
                    <h4>Listado del pedido</h4>
                    <svg width="22" height="22" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" alt="vectorBas" onClick={clear}><path d="M22.8 4.80132H16.8V2.79746C16.7718 2.0293 16.4402 1.30363 15.8779 0.779551C15.3155 0.255468 14.5683 -0.0242725 13.8 0.00165401H10.2C9.43171 -0.0242725 8.68447 0.255468 8.12212 0.779551C7.55975 1.30363 7.22815 2.0293 7.2 2.79746V4.80132H1.2C0.88174 4.80132 0.576515 4.92774 0.351472 5.15277C0.126428 5.3778 0 5.683 0 6.00124C0 6.31948 0.126428 6.62468 0.351472 6.84971C0.576515 7.07474 0.88174 7.20116 1.2 7.20116H2.4V20.4002C2.4 21.355 2.77928 22.2706 3.45442 22.9457C4.12955 23.6207 5.04522 24 6 24H18C18.9548 24 19.8705 23.6207 20.5456 22.9457C21.2207 22.2706 21.6 21.355 21.6 20.4002V7.20116H22.8C23.1183 7.20116 23.4235 7.07474 23.6485 6.84971C23.8736 6.62468 24 6.31948 24 6.00124C24 5.683 23.8736 5.3778 23.6485 5.15277C23.4235 4.92774 23.1183 4.80132 22.8 4.80132ZM9.6 16.8005C9.6 17.1187 9.47357 17.4239 9.24853 17.649C9.02348 17.874 8.71826 18.0004 8.4 18.0004C8.08174 18.0004 7.77652 17.874 7.55147 17.649C7.32643 17.4239 7.2 17.1187 7.2 16.8005V12.0008C7.2 11.6826 7.32643 11.3774 7.55147 11.1524C7.77652 10.9273 8.08174 10.8009 8.4 10.8009C8.71826 10.8009 9.02348 10.9273 9.24853 11.1524C9.47357 11.3774 9.6 11.6826 9.6 12.0008V16.8005ZM9.6 2.79746C9.6 2.60547 9.852 2.40149 10.2 2.40149H13.8C14.148 2.40149 14.4 2.60547 14.4 2.79746V4.80132H9.6V2.79746ZM16.8 16.8005C16.8 17.1187 16.6736 17.4239 16.4485 17.649C16.2235 17.874 15.9183 18.0004 15.6 18.0004C15.2817 18.0004 14.9765 17.874 14.7515 17.649C14.5264 17.4239 14.4 17.1187 14.4 16.8005V12.0008C14.4 11.6826 14.5264 11.3774 14.7515 11.1524C14.9765 10.9273 15.2817 10.8009 15.6 10.8009C15.9183 10.8009 16.2235 10.9273 16.4485 11.1524C16.6736 11.3774 16.8 11.6826 16.8 12.0008V16.8005Z" fill="#353535"/></svg>
                  </div>
                  <div className={cartItems.length >= 4 ? styles.containerItemsScroll : styles.containerItems}>
                    {cartItems.map((item, i) => (
                      <OrderItem key={i} item={item}/>
                    ))}
                  </div>
                  <div className={styles.precio}>
                    <div className={styles.flex}>
                      <h4>Envío</h4>
                      <p>$100.00</p>
                    </div>
                    <div className={styles.flex}>
                      <h4>Items totales</h4>
                      <p>${total}</p>
                    </div>
                  </div>
                  <button className={styles.btn} onClick={() => setOrden(true)}>Ir al checkout</button>
                </div>
                ) : (
                  <div className={styles.containerOrderMobile}>
                  <div>
                    <h2 className={styles.order}>Mi orden</h2>
                  </div>
                  <div className={styles.contList}>
                  <h4>Checkout</h4>
                  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setOrden(false)}><path fill-rule="evenodd" clip-rule="evenodd" d="M0.316867 0.330729C0.519817 0.127635 0.795041 0.0135436 1.08201 0.0135436C1.36899 0.0135436 1.64421 0.127635 1.84716 0.330729L6.49323 4.98148L11.1393 0.330729C11.2391 0.22726 11.3586 0.144729 11.4906 0.0879524C11.6226 0.031176 11.7647 0.00129087 11.9084 4.09035e-05C12.0521 -0.00120906 12.1946 0.0262009 12.3276 0.0806718C12.4606 0.135143 12.5814 0.215584 12.683 0.317301C12.7846 0.419018 12.865 0.539974 12.9194 0.673112C12.9738 0.80625 13.0012 0.948903 13 1.09275C12.9987 1.23659 12.9689 1.37875 12.9121 1.51092C12.8554 1.64309 12.773 1.76263 12.6696 1.86256L8.02353 6.51331L12.6696 11.1641C12.8667 11.3684 12.9758 11.642 12.9734 11.9261C12.9709 12.2101 12.8571 12.4818 12.6564 12.6827C12.4558 12.8836 12.1843 12.9975 11.9006 13C11.6168 13.0024 11.3434 12.8932 11.1393 12.6959L6.49323 8.04515L1.84716 12.6959C1.64305 12.8932 1.36967 13.0024 1.08591 13C0.802147 12.9975 0.530707 12.8836 0.33005 12.6827C0.129393 12.4818 0.0155741 12.2101 0.0131083 11.9261C0.0106425 11.642 0.119727 11.3684 0.316867 11.1641L4.96294 6.51331L0.316867 1.86256C0.113977 1.65941 0 1.38391 0 1.09665C0 0.809384 0.113977 0.533884 0.316867 0.330729Z" fill="#353535"/></svg>
                
                  </div>
                  <h5 className={styles.confirm}>Confirme su pedido</h5>

                  <div className={styles.Advertencia}>
                    <img src={Advertencia} alt="Advertencia"/>
                    <p>El pago se efectuará al llegar la orden, expecificamente en efectivo y el envío es propio del negocio. Al finalizar el checkout le llegará el recibo vía WhatsApp.</p>
                  </div>
                
                  <div className={styles.containerText}>
                    <div className={styles.infoClient}>
                      <p className={styles.infoClientQuest}>Ingrese su nombre*</p>
                      <p className={styles.infoClientResp}>{nombre}</p>
                    </div>
                
                    <div className={styles.infoClient}>
                      <p className={styles.infoClientQuest}>Ingrese su número de teléfono*</p>
                      <p className={styles.infoClientResp}>{numero}</p>
                    </div>
                
                    <div className={styles.infoClient}>
                      <p className={styles.infoClientQuest}>Ingrese su dirección*</p>
                      <p className={styles.infoClientResp}>{direccion}</p>
                    </div>
                
                    <div className={styles.infoClient}>
                      <p className={styles.infoClientQuest}>Ingrese su documento de identidad</p>
                      <p className={styles.infoClientResp}>{identidad}</p>
                    </div>
                
                    <div className={styles.infoClient}>
                      <p className={styles.infoClientQuest}>Especificaciones</p>
                      <p className={styles.infoClientResp}>{especificaciones}</p>
                    </div>
                  </div>
                  <button className={styles.btn2} onClick={() => setOrden(true)}>Generar recibo</button>
                </div>
              )}
            </Sheet.Content>
        </Sheet.Container>

        <Sheet.Backdrop />
      </Sheet>
    </>
  )
}