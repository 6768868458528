import React from 'react'
import Main from "../Main"
import { Order } from '../Order'

export const Inicio = () => {
  return (
    <>
     <Main />
     <Order />
    </>
  )
}
