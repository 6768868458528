const datamenu = [
    {
        id: 1,
        title: "Philadelphia",
        category: "Rolls",
        price: 3600,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684459297/PhiladelphiaRoll_ovk7pw.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812201/DonRemolo/Pizzas_pkmduj.svg",     
    },
    {
        id: 3,
        title: "New York",
        category: "Rolls",
        price: 3600,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684460503/2ba0c448-bb0d-4381-b543-e9ed73e4eb5f_zgcl8h.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812201/DonRemolo/Pizzas_pkmduj.svg",
    },
    {
        id: 4,
        title: "Tare",
        category: "Rolls",
        price: 3600,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684461346/27cc0a4b-6c47-4f95-aaa7-0ada89951753_1_qgvrht.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812201/DonRemolo/Pizzas_pkmduj.svg",
    },
    {
        id: 5,
        title: "Nigiri Salomón",
        category: "Nigiri",
        price: 320,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684462231/fecd45b4-0887-41c3-9974-13e4837d6a0d_frd3dw.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812223/DonRemolo/Empanadas_oojijo.svg",
    },
    {
        id: 6,
        title: "Nigiri Lango",
        category: "Nigiri",
        price: 320,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684462359/fa4d810e-fce0-4cea-b7a9-d19e3403c5a8_dmvcff.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812223/DonRemolo/Empanadas_oojijo.svg",
    },
    {
        id: 7,
        title: "Nigiri Trufa",
        category: "Nigiri",
        price: 1500,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684462927/a01253d2-eadb-4c5b-a48b-86ec7c60f73e_pbpgj2.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812223/DonRemolo/Empanadas_oojijo.svg",
    },
    {
        id: 8,
        title: "Maki de Salmón",
        category: "Makis",
        price: 3600,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684465160/makisalmon_namm1l.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812227/DonRemolo/Bebidas_cy38co.svg",
    },
    {
        id: 9,
        title: "Maki Cali",
        category: "Makis",
        price: 3600,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684465663/makicali_r9bgs0.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812227/DonRemolo/Bebidas_cy38co.svg",
    },
    {
        id: 10,
        title: "Maki Tuna",
        category: "Makis",
        price: 3600,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684465925/makituna_hwqlip.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812227/DonRemolo/Bebidas_cy38co.svg",
    },
    {
        id: 11,
        title: "Jinsei (30u)",
        category: "Combinados",
        price: 8000,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684467134/15537608-438f26a9-1a36-4084-8b91-755317aeb199_qtbtak.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812201/DonRemolo/Postres_np8nfq.svg",
    },
    {
        id: 12,
        title: "Tomodachi (60u)",
        category: "Combinados",
        price: 14000,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684467126/15537652-8d65e7df-71de-4df0-ae55-253fe2b57812_umjlhw.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812201/DonRemolo/Postres_np8nfq.svg",
    },
    {
        id: 13,
        title: "Guro (45u)",
        category: "Combinados",
        price: 11000,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684467272/15537624-5a7a11a6-8ac6-4ad3-96c4-974b2f25215c_wufqor.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812201/DonRemolo/Postres_np8nfq.svg",
    },
    {
        id: 14,
        title: "Philadelphia",
        category: "Rolls",
        price: 3600,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684459297/PhiladelphiaRoll_ovk7pw.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812201/DonRemolo/Pizzas_pkmduj.svg",     
    },
    {
        id: 15,
        title: "New York",
        category: "Rolls",
        price: 3600,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684460503/2ba0c448-bb0d-4381-b543-e9ed73e4eb5f_zgcl8h.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812201/DonRemolo/Pizzas_pkmduj.svg",
    },
    {
        id: 16,
        title: "Tare",
        category: "Rolls",
        price: 3600,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684461346/27cc0a4b-6c47-4f95-aaa7-0ada89951753_1_qgvrht.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812201/DonRemolo/Pizzas_pkmduj.svg",
    },
    {
        id: 17,
        title: "Nigiri Salomón",
        category: "Nigiri",
        price: 320,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684462231/fecd45b4-0887-41c3-9974-13e4837d6a0d_frd3dw.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812223/DonRemolo/Empanadas_oojijo.svg",
    },
    {
        id: 18,
        title: "Nigiri Lango",
        category: "Nigiri",
        price: 320,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684462359/fa4d810e-fce0-4cea-b7a9-d19e3403c5a8_dmvcff.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812223/DonRemolo/Empanadas_oojijo.svg",
    },
    {
        id: 19,
        title: "Nigiri Trufa",
        category: "Nigiri",
        price: 1500,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684462927/a01253d2-eadb-4c5b-a48b-86ec7c60f73e_pbpgj2.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812223/DonRemolo/Empanadas_oojijo.svg",
    },
    {
        id: 20,
        title: "Maki de Salmón",
        category: "Makis",
        price: 3600,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684465160/makisalmon_namm1l.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812227/DonRemolo/Bebidas_cy38co.svg",
    },
    {
        id: 21,
        title: "Maki Cali",
        category: "Makis",
        price: 3600,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684465663/makicali_r9bgs0.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812227/DonRemolo/Bebidas_cy38co.svg",
    },
    {
        id: 22,
        title: "Maki Tuna",
        category: "Makis",
        price: 3600,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684465925/makituna_hwqlip.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812227/DonRemolo/Bebidas_cy38co.svg",
    },
    {
        id: 23,
        title: "Jinsei (30u)",
        category: "Combinados",
        price: 8000,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684467134/15537608-438f26a9-1a36-4084-8b91-755317aeb199_qtbtak.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812201/DonRemolo/Postres_np8nfq.svg",
    },
    {
        id: 24,
        title: "Tomodachi (60u)",
        category: "Combinados",
        price: 14000,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684467126/15537652-8d65e7df-71de-4df0-ae55-253fe2b57812_umjlhw.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812201/DonRemolo/Postres_np8nfq.svg",
    },
    {
        id: 25,
        title: "Guro (45u)",
        category: "Combinados",
        price: 11000,
        img: "https://res.cloudinary.com/dkptsvzek/image/upload/v1684467272/15537624-5a7a11a6-8ac6-4ad3-96c4-974b2f25215c_wufqor.webp",
        vector: "https://res.cloudinary.com/dkptsvzek/image/upload/v1664812201/DonRemolo/Postres_np8nfq.svg",
    }
]

export default datamenu