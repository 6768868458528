import React from 'react';
import Sidebar from './components/SideBar';
import './App.scss';
import {Routes, Route, BrowserRouter} from "react-router-dom"
import { Inicio } from './components/Inicio';
import Favoritos from './components/Favoritos';
import {CartProvider} from "./Context/CartContext";
import Ayuda from './components/Ayuda';
import Preferencias from './components/Preferencias';
import useLocalStorage from 'use-local-storage'

const App = () => {
  const defaultDark = window.matchMedia('(prefers-color-scheme: dark)').matches;
  const [theme, setTheme] = useLocalStorage('theme', defaultDark ? 'dark' : 'dark');

  const switchTheme = () => {
    const newTheme = theme === 'dark' ? 'dark' : 'dark';
    setTheme(newTheme);
  };

  return (
    <div className='flex' data-theme={theme}>
      <BrowserRouter>
        <CartProvider> 
          <Sidebar />
            <Routes>
              <Route path="/" exact element={<Inicio/>} />
              <Route path="/favoritos" element={<Favoritos/>} />
              <Route path="/preferencias" element={<Preferencias switchTheme={switchTheme} theme={theme}/>} />
              <Route path="/ayuda" element={<Ayuda/>} />
            </Routes>
        </CartProvider>
      </BrowserRouter>
    </div> 
  );
};

export default App;