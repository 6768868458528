import React from 'react'
import styles from './styles.module.scss';
import { useState, useContext } from 'react';
import dataMenu from "../../data/dataMenu";
import { CartContext } from '../../Context/CartContext.jsx'

export const SearchBar = () => {
    const {addItemToCart, addItemToFavorite} = useContext(CartContext)

    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");

    const handleFilter = (event) => {
      const searchWord = event.target.value;
      setWordEntered(searchWord);
      const newFilter = dataMenu.filter((value) => {
        return value.title.toLowerCase().includes(searchWord.toLowerCase());
      });

      if (searchWord === "") {
        setFilteredData([]);
      } else {
        setFilteredData(newFilter);
      }
    };

    // const clearInput = () => {
    //   setFilteredData([]);
    //   setWordEntered("");
    // };

  return (
    <div className={styles.search}>
      <div className={styles.searchInputs}>
        <i className='fas fa-search'></i>
        <input
          type="text"
          placeholder="Busca algo de nuestro menú..."
          value={wordEntered}
          onChange={handleFilter}
        />
      </div>
      {filteredData.length != 0 && (
        <div className={styles.dataResult}>
          {filteredData.slice(0, 15).map((value, key) => {
            return (
              <a className={styles.dataItem}>
                <div className={styles.flexCent}>
                  <img className={styles.imagenSearch} src={value.img}></img>
                  <p>{value.title} </p>
                </div>
                <div className={styles.flexCent}>
                  <svg width="22" height="22" viewBox="0 0 35 32" fill="none" xmlns="http://www.w3.org/2000/svg" className={styles.svgMarg} onClick={() => addItemToFavorite(value)}><path d="M16.1637 3.1523C15.2979 2.24895 14.2588 1.52941 13.1087 1.03667C11.9585 0.543923 10.7208 0.288093 9.46949 0.284463C8.21821 0.280833 6.97904 0.529477 5.82602 1.01554C4.673 1.5016 3.62979 2.2151 2.75872 3.11341C-0.935721 6.90508 -0.916277 13.0651 2.79761 16.8684L16.5254 30.9579C17.0932 31.5412 18.0187 31.5412 18.5865 30.9579L32.2482 16.9501C34.0212 15.0986 35.0077 12.6321 35.0005 10.0686C34.9932 7.50516 33.9928 5.04425 32.2093 3.20286C31.3427 2.29845 30.3027 1.57811 29.1513 1.08494C27.9999 0.591768 26.7609 0.335896 25.5084 0.332629C24.2559 0.329361 23.0155 0.578766 21.8616 1.06592C20.7077 1.55308 19.6639 2.26798 18.7926 3.16786L17.486 4.50952L16.1637 3.14841V3.1523Z" fill="#A6A6A6"/></svg>
                  <button className={styles.svgMore2} onClick={() => addItemToCart(value)}>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M6.28571 11.5714V6.28571M6.28571 6.28571V1M6.28571 6.28571H11.5714M6.28571 6.28571H1" stroke="#AEAEAE" strokeWidth="2" strokeLinecap="round"/>
                    </svg>
                  </button>
                </div>
              </a>
            );
          })}
        </div>
      )}
    </div>
  )
}