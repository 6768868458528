import React from 'react'
import styles from './styles.module.scss'

const Ayuda = () => {
  return (
    <div className={styles.container}>
        <h2>Centro de ayuda</h2>
        <p>Te contamos como funciona la página</p>

        <div className={styles.preguntas}>
            <h3>Preguntas</h3>
            <div className={styles.containerQuestion}>
                <p className={styles.pregunta}>¿Cómo se puede pagar el pedido?</p>
                <p>En efectivo y al momento en que llegue su pedido, anteriormente comunicado en el Checkout</p>
            </div>
            <div className={styles.containerQuestion}>
                <p className={styles.pregunta}>¿Cuánto tarda en llegar el pedido?</p>
                <p>Dependiendo de su ubicación, para su mayor comodidad nuestra dirección es av. Avenida 9999 para calcular un tiempo estimado</p>
            </div>
            <div className={styles.containerQuestion}>
                <p className={styles.pregunta}>¿Cómo hago mi pedido?</p>
                <p>1. Escoja su orden</p>
                <p>2. Vaya al listado de su pedido y clickee en "Ir al checkout"</p>
                <p>3. Complete sus datos, genere el recibo que le llegará via WhatsApp</p>
                <p>4. Espere su pedido y disfrute</p>
            </div>
        </div>

        <div className={styles.reportar}>
            <h3>Reportar un problema</h3>
            <p>Si tiene algún problema con su pedido, por favor comuníquese con nosotros</p>
            <form>
                <input type="email" placeholder="Correo eléctronico" />
                <input type="text" placeholder="Asunto" />
                <textarea placeholder="Descripción" />
                <button type="submit">Enviar</button>
            </form>
        </div>
    </div>
  )
}

export default Ayuda