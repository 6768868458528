import { createContext, useEffect, useState } from 'react'

export const CartContext = createContext()

export const CartProvider = ({ children }) => {
  const [totalQuantity, setTotalQuantity] = useState(0)
  const [totalPrice, setTotalPrice] = useState(0)
  const [cartItems, setCartItems] = useState(() => {
    try {
      const productosEnLocalStorage = localStorage.getItem('cartProducts')
      return productosEnLocalStorage ? JSON.parse(productosEnLocalStorage) : []
    } catch (error) {
      return []
    }
  })

  useEffect(() => {
    localStorage.setItem('cartProducts', JSON.stringify(cartItems))
  } , [cartItems])

  const addItemToCart = (product) => {
    const inCart = cartItems.find(
      (productInCart) => productInCart.id === product.id
    )

    if (inCart) {
      setCartItems(
        cartItems.map((productInCart) => {
          if (productInCart.id === product.id) {
            return { ...inCart, amount: inCart.amount + 1 }
          } else return productInCart
        })
      )
    } else {
      setCartItems([...cartItems, { ...product, amount: 1 }])
    }}

    const deleteItemToCart = (product) => {
      const inCart = cartItems.find(
        (productInCart) => productInCart.id === product.id
      )

      if (inCart.amount === 1) {
        setCartItems(
          cartItems.filter((productInCart) => productInCart.id !== product.id)
        )
      } else {
        setCartItems(
          cartItems.map((productInCart) => {
          if (productInCart.id === product.id) {
            return { ...inCart, amount: inCart.amount - 1 }
          } else return productInCart
        }))
      }
    }

    const clear = () => {
      setCartItems([])
      setTotalQuantity(0)
      setTotalPrice(0)
    }

    const [favoriteItems, setFavoriteItems] = useState(() => {
      try {
        const productosEnLocalStorageFavorite = localStorage.getItem('favoriteProducts')
        return productosEnLocalStorageFavorite ? JSON.parse(productosEnLocalStorageFavorite) : []
      } catch (error) {
        return []
      }
    })

    useEffect(() => {
      localStorage.setItem('favoriteProducts', JSON.stringify(favoriteItems))
    } , [favoriteItems])

    const addItemToFavorite = (product) => {
      const inFavorite = favoriteItems.find(
        (productInFavorite) => productInFavorite.id === product.id
      )

      if (inFavorite) {
        setFavoriteItems(
          favoriteItems.map((productInFavorite) => {
            if (productInFavorite.id === product.id) {
              return { ...inFavorite, amount: inFavorite.amount + 1 }
            } else return productInFavorite
          })
        )
      } else {
        setFavoriteItems([...favoriteItems, { ...product, amount: 1 }])
      }}

      const deleteItemToFavorite = (product) => {
        const inFavorite = favoriteItems.find(
          (productInFavorite) => productInFavorite.id === product.id
        )

        if (inFavorite.amount === 1) {
          setFavoriteItems(
            favoriteItems.filter((productInFavorite) => productInFavorite.id !== product.id)
          )
        } else {
          setFavoriteItems(
            favoriteItems.map((productInFavorite) => {
            if (productInFavorite.id === product.id) {
              return { ...inFavorite, amount: inFavorite.amount - 1 }
            } else return productInFavorite
          }))
        }
      }

      const clearFavorite = () => {
        setFavoriteItems([])
      }

    return (
      <CartContext.Provider 
        value={{ cartItems, addItemToCart, deleteItemToCart, clear, addItemToFavorite, favoriteItems, deleteItemToFavorite }}> 
        {children}
      </CartContext.Provider>
    )
}