import React from 'react'
import { Order } from '../Order'
import FavoriteSection from '../FavoriteSection'

const Favoritos = () => {
  return (
    <>
    <FavoriteSection />
    <Order />
    </>
  )
}

export default Favoritos