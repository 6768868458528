import React from 'react'
import styles from './styles.module.scss'
import dataMenu from "../../data/dataMenu";
import { useState, useContext, useEffect } from 'react'
import { CartContext } from '../../Context/CartContext';
import { FavoriteItem } from '../FavoriteItem';


const FavoriteSection = () => {
  const {favoriteItems, deleteItemToFavorite} = useContext(CartContext)
  return (
    <div className={styles.container}>
        <h2>Favoritos</h2>
        <p>Guarda tus comidas favoritas de nuestro menu</p>
        <div className={styles.itemsContainer}>
        {favoriteItems.map((item, i) => (
          <FavoriteItem key={i} item={item}/>
        ))}
        </div>
    </div>
  )
}

export default FavoriteSection