import React from 'react'
import styles from './styles.module.scss'
import { useState, useEffect } from 'react';

const Preferencias = ({theme, switchTheme}) => {
    const [checked, setChecked] = useState(false);
    const [inputText, setInputText] = useState('');
    const [inputTextNumero, setInputTextNumero] = useState('');
    const [inputTextDireccion, setInputTextDireccion] = useState('');
    const [inputTextIdentidad, setInputTextIdentidad] = useState('');
    const [inputTextEspecificaciones, setInputTextEspecificaciones] = useState('');
    const [savedData, setSavedData] = useState(false);
    const [savedDataNumero, setSavedDataNumero] = useState(false);
    const [savedDataDireccion, setSavedDataDireccion] = useState(false);
    const [savedDataIdentidad, setSavedDataIdentidad] = useState(false);
    const [savedDataEspecificaciones, setSavedDataEspecificaciones] = useState(false);

    const handleInputChange = (e) => {
        const text = e.target.value
        setInputText(text);
        console.log(inputText);

        if(text === '') {
            setSavedData(false);
        }
    }
    const saveData = () => {
        localStorage.setItem('nombre', inputText);
        setSavedData(true);
    }

    const handleInputChangeNumero = (e) => {
        const text = e.target.value
        setInputTextNumero(text);
        console.log(inputTextNumero);

        if(text === '') {
            setSavedDataNumero(false);
        }
    }
    const saveDataNumero = () => {
        localStorage.setItem('numero', inputTextNumero);
        setSavedDataNumero(true);
    }

    const handleInputChangeDireccion = (e) => {
        const text = e.target.value
        setInputTextDireccion(text);
        console.log(inputTextDireccion);
        
        if(text === '') {
            setSavedDataDireccion(false);
        }
    }
    const saveDataDireccion = () => {
        localStorage.setItem('direccion', inputTextDireccion);
        setSavedDataDireccion(true);
    }

    const handleInputChangeIdentidad = (e) => {
        const text = e.target.value
        setInputTextIdentidad(text);
        console.log(inputTextIdentidad);

        if(text === '') {
            setSavedDataIdentidad(false);
        }
    }
    const saveDataIdentidad = () => {
        localStorage.setItem('identidad', inputTextIdentidad);
        setSavedDataIdentidad(true);
    }

    const handleInputChangeEspecificaciones = (e) => {
        const text = e.target.value
        setInputTextEspecificaciones(text);
        console.log(inputTextEspecificaciones);
        
        if(text === '') {
            setSavedDataEspecificaciones(false);
        }
    }
    const saveDataEspecificaciones = () => {
        localStorage.setItem('especificaciones', inputTextEspecificaciones);
        setSavedDataEspecificaciones(true);
    }

  return (
    <div className={styles.container}>
        <h2>Preferencias</h2>
        <p>Configura la página, tema oscuro/claro y tus datos para el checkout</p>

        <div className={styles.containerInputs}>
            <h5>Configura tu nombre</h5>
            <input type="text" placeholder="Nombre" onChange={handleInputChange}/>
            <button className='button' onClick={saveData}>Guardar</button>
        </div>

        <div className={styles.containerInputs}>
            <h5>Configura tu número de teléfono</h5>
            <input type="text" placeholder="Número de teléfono" onChange={handleInputChangeNumero}/>
            <button className='button' onClick={saveDataNumero}>Guardar</button>
        </div>

        <div className={styles.containerInputs}>
            <h5>Configura tu dirección</h5>
            <input type="text" placeholder="Dirección" onChange={handleInputChangeDireccion}/>
            <button className='button' onClick={saveDataDireccion}>Guardar</button>
        </div>

        <div className={styles.containerInputs}>
            <h5>Configura tu documento de identidad</h5>
            <input type="text" placeholder="Documento de identidad" onChange={handleInputChangeIdentidad}/>
            <button className='button' onClick={saveDataIdentidad}>Guardar</button>
        </div>

        <div className={styles.containerInputs}>
            <h5>Especificaciones</h5>
            <input type="text" placeholder="Especificaciones" onChange={handleInputChangeEspecificaciones}/>
            <button className='button' onClick={saveDataEspecificaciones}>Guardar</button>
        </div>

        <div className={styles.containerInputs2}>
            <h5>Tema oscuro</h5>
            <div className={styles.wrapper}>
            <input type="checkbox" name="checkboxTheme" className={styles.switch} onChange={switchTheme} checked={theme === "dark" ? false : true}/>
            </div>
        </div>
    </div>
  )
}
export default Preferencias